import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { SOCIAL_MEDIA_LINKS } from '../constants';

export default function FloatingSidebar() {
    return (
        // Ensure sidebar is hidden on mobile and doesn't affect the layout
        <div className="fixed top-1/4 right-0 z-50 hidden md:flex flex-col space-y-3">
            <a href={SOCIAL_MEDIA_LINKS.facebook} target="_blank" rel="noopener noreferrer" className="group">
                <div className="dark:bg-primary-light bg-primary-dark dark:text-primary-light text-primary-dark p-3 rounded-l-lg shadow-lg group-hover:bg-blue-600 transition">
                    <FaFacebook className="h-6 w-6" />
                </div>
            </a>
            <a href={SOCIAL_MEDIA_LINKS.linkedin} target="_blank" rel="noopener noreferrer" className="group">
                <div className="dark:bg-primary-light bg-primary-dark dark:text-primary-light text-primary-dark p-3 rounded-l-lg shadow-lg group-hover:bg-blue-400 transition">
                    <FaLinkedin className="h-6 w-6" />
                </div>
            </a>
            <a href={SOCIAL_MEDIA_LINKS.instagram} target="_blank" rel="noopener noreferrer" className="group">
                <div className="dark:bg-primary-light bg-primary-dark dark:text-primary-light text-primary-dark p-3 rounded-l-lg shadow-lg group-hover:bg-pink-600 transition">
                    <FaInstagram className="h-6 w-6" />
                </div>
            </a>
        </div>
    );
}
